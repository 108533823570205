// PEOPLE IMAGE IMPORTS
import pinkBlonde from "C:/Users/Sean/Documents/VSCode Files/giulia_website/src/Images/people/Debby-Harry-min.jpg";
import handHold from "C:/Users/Sean/Documents/VSCode Files/giulia_website/src/Images/people/I-Wanna-hold-your-hand--min.jpg";
import yellowStripe from "C:/Users/Sean/Documents/VSCode Files/giulia_website/src/Images/people/Opera-senza-titolo 1-min.jpg";
import greenStripe from "C:/Users/Sean/Documents/VSCode Files/giulia_website/src/Images/people/Opera-senza-titolo-min.jpg";
import redStripe from "C:/Users/Sean/Documents/VSCode Files/giulia_website/src/Images/people/Scarabocchi-min.jpg";
import orangeStripe from "C:/Users/Sean/Documents/VSCode Files/giulia_website/src/Images/people/Scarabocchi--min.jpg";
import spaceMan from "C:/Users/Sean/Documents/VSCode Files/giulia_website/src/Images/people/Stelle-min.jpg";
import mouth from "C:/Users/Sean/Documents/VSCode Files/giulia_website/src/Images/people/Opera-senza-titolo 3_trimmed-min.jpg";
import drinkTea from "C:/Users/Sean/Documents/VSCode Files/giulia_website/src/Images/people/Opera-senza-titolo 2-min.jpg";
import astro from "C:/Users/Sean/Documents/VSCode Files/giulia_website/src/Images/people/astro.jpeg";
import baby from "C:/Users/Sean/Documents/VSCode Files/giulia_website/src/Images/people/baby.jpeg";
import mia from "C:/Users/Sean/Documents/VSCode Files/giulia_website/src/Images/people/Mia_Wallace_-min.png";
import nada from "C:/Users/Sean/Documents/VSCode Files/giulia_website/src/Images/people/Nada_Malanima-min.png"
import unnamed from "C:/Users/Sean/Documents/VSCode Files/giulia_website/src/Images/people/unnamed-min.png"
import women from "C:/Users/Sean/Documents/VSCode Files/giulia_website/src/Images/people/women.jpeg"

// ANIMALS IMAGE IMPORTS
import frog from "C:/Users/Sean/Documents/VSCode Files/giulia_website/src/Images/animals/Rana-min.jpg";
import whale from "C:/Users/Sean/Documents/VSCode Files/giulia_website/src/Images/animals/Balena-min.jpg";
import chameleon from "C:/Users/Sean/Documents/VSCode Files/giulia_website/src/Images/animals/Camaleonte--min.jpg";
import clownFish from "C:/Users/Sean/Documents/VSCode Files/giulia_website/src/Images/animals/ClownFish-min.jpg";
import giraffe from "C:/Users/Sean/Documents/VSCode Files/giulia_website/src/Images/animals/Giraffa-min.jpg";
import monkey from "C:/Users/Sean/Documents/VSCode Files/giulia_website/src/Images/animals/Mandrillo--min.jpg";
import cow from "C:/Users/Sean/Documents/VSCode Files/giulia_website/src/Images/animals/Mucca-min.jpg";
import turtle from "C:/Users/Sean/Documents/VSCode Files/giulia_website/src/Images/animals/Opera-senza-titolo 1-min.jpg";
import killerWhale from "C:/Users/Sean/Documents/VSCode Files/giulia_website/src/Images/animals/Opera-senza-titolo-min.jpg";
import cats from "C:/Users/Sean/Documents/VSCode Files/giulia_website/src/Images/animals/Regalo-Borghi-min.jpg";
import squirell from "C:/Users/Sean/Documents/VSCode Files/giulia_website/src/Images/animals/San-Valentino-min.jpg";
import walrus from "C:/Users/Sean/Documents/VSCode Files/giulia_website/src/Images/animals/Tricheco--min.jpg";
import jellyfish from "C:/Users/Sean/Documents/VSCode Files/giulia_website/src/Images/animals/jellyfish-min.png";

// COMMISSION IMAGE IMPORTS
import comm_1 from "C:/Users/Sean/Documents/VSCode Files/giulia_website/src/Images/commissions/Giulia--e-Elisabetta--min.jpg";
import comm_2 from "C:/Users/Sean/Documents/VSCode Files/giulia_website/src/Images/commissions/Jessy-E-Fede-min.jpg";
import comm_3 from "C:/Users/Sean/Documents/VSCode Files/giulia_website/src/Images/commissions/Opera-senza-titolo-min.jpg";
import comm_4 from "C:/Users/Sean/Documents/VSCode Files/giulia_website/src/Images/commissions/Paolo--min.jpg";
import comm_5 from "C:/Users/Sean/Documents/VSCode Files/giulia_website/src/Images/commissions/Pluto-e-Alessandro--min.jpg";
import comm_6 from "C:/Users/Sean/Documents/VSCode Files/giulia_website/src/Images/commissions/Poinelli-min.jpg";
import comm_7 from "C:/Users/Sean/Documents/VSCode Files/giulia_website/src/Images/commissions/Roberto-E-Silvia-min.jpg";
import comm_8 from "C:/Users/Sean/Documents/VSCode Files/giulia_website/src/Images/commissions/Sofia--min.jpg";
import comm_9 from "C:/Users/Sean/Documents/VSCode Files/giulia_website/src/Images/commissions/chalkBag-min.jpg";
import comm_10 from "C:/Users/Sean/Documents/VSCode Files/giulia_website/src/Images/commissions/Enrico's_Dream-min.png";
import comm_11 from "C:/Users/Sean/Documents/VSCode Files/giulia_website/src/Images/commissions/Daris_e_Syria 1-min.png";
import comm_12 from "C:/Users/Sean/Documents/VSCode Files/giulia_website/src/Images/commissions/Daris_e_Syria-min.png";

// FOOD IMAGE IMPORTS
import watermelon from "C:/Users/Sean/Documents/VSCode Files/giulia_website/src/Images/food_and_beverages/Anguriaaaaaa-min.jpg";
import cocktails from "C:/Users/Sean/Documents/VSCode Files/giulia_website/src/Images/food_and_beverages/Cocktails-min.jpg";
import donuts from "C:/Users/Sean/Documents/VSCode Files/giulia_website/src/Images/food_and_beverages/Donuts--min.jpg";
import gin from "C:/Users/Sean/Documents/VSCode Files/giulia_website/src/Images/food_and_beverages/GinTonic-min.jpg";
import hamburger from "C:/Users/Sean/Documents/VSCode Files/giulia_website/src/Images/food_and_beverages/Hamburger--min.jpg";
import cupCake from "C:/Users/Sean/Documents/VSCode Files/giulia_website/src/Images/food_and_beverages/Opera-senza-titolo 1-min.jpg";
import hotdog from "C:/Users/Sean/Documents/VSCode Files/giulia_website/src/Images/food_and_beverages/Opera-senza-titolo-min.jpg";
import chips from "C:/Users/Sean/Documents/VSCode Files/giulia_website/src/Images/food_and_beverages/Patatineeeeee-min.jpg";
import pizza from "C:/Users/Sean/Documents/VSCode Files/giulia_website/src/Images/food_and_beverages/Pizzaaaa-min.jpg";
import tea from "C:/Users/Sean/Documents/VSCode Files/giulia_website/src/Images/food_and_beverages/tea-min.png";

// PATTERN IMAGE IMPORTS
import pattern_1 from "C:/Users/Sean/Documents/VSCode Files/giulia_website/src/Images/patterns/Opera-senza-titolo 1-min.jpg";
import pattern_2 from "C:/Users/Sean/Documents/VSCode Files/giulia_website/src/Images/patterns/Opera-senza-titolo-min.jpg";
import pattern_3 from "C:/Users/Sean/Documents/VSCode Files/giulia_website/src/Images/patterns/Pattern 1-min.jpg";
import pattern_4 from "C:/Users/Sean/Documents/VSCode Files/giulia_website/src/Images/patterns/Prova-Pattern-min.jpg";
import pattern_5 from "C:/Users/Sean/Documents/VSCode Files/giulia_website/src/Images/patterns/Prova-Pattern--min.jpg";
import pattern_6 from "C:/Users/Sean/Documents/VSCode Files/giulia_website/src/Images/patterns/Syria 1-min.png";
import pattern_7 from "C:/Users/Sean/Documents/VSCode Files/giulia_website/src/Images/patterns/Syria-min.png";

// BOOK IMAGE IMPORTS
import book_1 from "C:/Users/Sean/Documents/VSCode Files/giulia_website/src/Images/childrens_book/Capitolo-10-min.jpg";
import book_2 from "C:/Users/Sean/Documents/VSCode Files/giulia_website/src/Images/childrens_book/Capitolo-11-min.jpg";
import book_3 from "C:/Users/Sean/Documents/VSCode Files/giulia_website/src/Images/childrens_book/Capitolo-12-min.jpg";
import book_4 from "C:/Users/Sean/Documents/VSCode Files/giulia_website/src/Images/childrens_book/Capitolo-13-min.jpg";
import book_5 from "C:/Users/Sean/Documents/VSCode Files/giulia_website/src/Images/childrens_book/Capitolo-14-min.jpg";
import book_6 from "C:/Users/Sean/Documents/VSCode Files/giulia_website/src/Images/childrens_book/Capitolo-15-min.jpg";
import book_7 from "C:/Users/Sean/Documents/VSCode Files/giulia_website/src/Images/childrens_book/Capitolo-2-min.jpg";
import book_8 from "C:/Users/Sean/Documents/VSCode Files/giulia_website/src/Images/childrens_book/Capitolo-3-min.jpg";
import book_9 from "C:/Users/Sean/Documents/VSCode Files/giulia_website/src/Images/childrens_book/Capitolo-4-min.jpg";
import book_10 from "C:/Users/Sean/Documents/VSCode Files/giulia_website/src/Images/childrens_book/Capitolo-5-min.jpg";
import book_11 from "C:/Users/Sean/Documents/VSCode Files/giulia_website/src/Images/childrens_book/Capitolo-6-min.jpg";
import book_12 from "C:/Users/Sean/Documents/VSCode Files/giulia_website/src/Images/childrens_book/Capitolo-7-min.jpg";
import book_13 from "C:/Users/Sean/Documents/VSCode Files/giulia_website/src/Images/childrens_book/Capitolo-8-min.jpg";
import book_14 from "C:/Users/Sean/Documents/VSCode Files/giulia_website/src/Images/childrens_book/Capitolo1-min.jpg";
import book_15 from "C:/Users/Sean/Documents/VSCode Files/giulia_website/src/Images/childrens_book/Copertina-con-titolo-min.jpg";
import book_16 from "C:/Users/Sean/Documents/VSCode Files/giulia_website/src/Images/childrens_book/Copertina-senza-titolo--min.jpg";
import book_17 from "C:/Users/Sean/Documents/VSCode Files/giulia_website/src/Images/childrens_book/Noah--min.jpg";
import book_18 from "C:/Users/Sean/Documents/VSCode Files/giulia_website/src/Images/childrens_book/Nuria--min.jpg";

export const peopleProject = [
  {
    key: 0,
    path: pinkBlonde,
  },
  {
    key: 1,
    path: handHold,
  },
  {
    key: 2,
    path: yellowStripe,
  },
  {
    key: 3,
    path: greenStripe,
  },
  {
    key: 4,
    path: redStripe,
  },
  {
    key: 5,
    path: orangeStripe,
  },
  {
    key: 6,
    path: spaceMan,
  },
  {
    key: 7,
    path: mouth,
  },
  {
    key: 8,
    path: drinkTea,
  },
  {
    key: 9,
    path: astro,
  },
  {
    key: 10,
    path: baby,
  },
  {
    key: 11,
    path: mia,
  },
  {
    key: 12,
    path: nada,
  },
  {
    key: 13,
    path: unnamed,
  },
  {
    key: 14,
    path: women,
  },
];

export const animalsProject = [
  {
    key: 0,
    path: frog,
  },
  {
    key: 1,
    path: whale,
  },
  {
    key: 2,
    path: chameleon,
  },
  {
    key: 3,
    path: clownFish,
  },
  {
    key: 4,
    path: giraffe,
  },
  {
    key: 5,
    path: monkey,
  },
  {
    key: 6,
    path: cow,
  },
  {
    key: 7,
    path: turtle,
  },
  {
    key: 8,
    path: walrus,
  },
  {
    key: 9,
    path: cats,
  },
  {
    key: 10,
    path: squirell,
  },
  {
    key: 11,
    path: killerWhale,
  },
  {
    key: 12,
    path: jellyfish,
  },
];

export const commissionProject = [
  {
    key: 0,
    path: comm_1,
  },
  {
    key: 1,
    path: comm_3,
  },
  {
    key: 2,
    path: comm_2,
  },
  {
    key: 3,
    path: comm_4,
  },
  {
    key: 4,
    path: comm_5,
  },
  {
    key: 5,
    path: comm_8,
  },
  {
    key: 6,
    path: comm_7,
  },
  {
    key: 7,
    path: comm_6,
  },
  {
    key: 8,
    path: comm_9,
  },
  {
    key: 9,
    path: comm_10,
  },
  {
    key: 10,
    path: comm_11,
  },
  {
    key: 11,
    path: comm_12,
  },
];

export const foodProject = [
  {
    key: 0,
    path: watermelon,
  },
  {
    key: 1,
    path: cocktails,
  },
  {
    key: 2,
    path: donuts,
  },
  {
    key: 3,
    path: gin,
  },
  {
    key: 4,
    path: hamburger,
  },
  {
    key: 5,
    path: cupCake,
  },
  {
    key: 6,
    path: hotdog,
  },
  {
    key: 7,
    path: chips,
  },
  {
    key: 8,
    path: pizza,
  },
  {
    key: 9,
    path: tea,
  },
];

export const patternProject = [
  {
    key: 0,
    path: pattern_1,
  },
  {
    key: 1,
    path: pattern_2,
  },
  {
    key: 2,
    path: pattern_3,
  },
  {
    key: 3,
    path: pattern_4,
  },
  {
    key: 4,
    path: pattern_5,
  },
  {
    key: 5,
    path: pattern_6,
  },
  {
    key: 6,
    path: pattern_7,
  },
];

export const bookProject = [
  {
    key: 0,
    path: book_1,
  },
  {
    key: 1,
    path: book_2,
  },
  {
    key: 2,
    path: book_3,
  },
  {
    key: 3,
    path: book_4,
  },
  {
    key: 4,
    path: book_5,
  },
  {
    key: 5,
    path: book_6,
  },
  {
    key: 6,
    path: book_7,
  },
  {
    key: 7,
    path: book_8,
  },
  {
    key: 8,
    path: book_9,
  },
  {
    key: 9,
    path: book_10,
  },
  {
    key: 10,
    path: book_11,
  },
  {
    key: 11,
    path: book_12,
  },
  {
    key: 12,
    path: book_13,
  },
  {
    key: 13,
    path: book_14,
  },
  {
    key: 14,
    path: book_15,
  },
  {
    key: 15,
    path: book_16,
  },
  {
    key: 16,
    path: book_17,
  },
  {
    key: 17,
    path: book_18,
  },
];
