import pattern from "C:/Users/Sean/Documents/VSCode Files/giulia_website/src/Images/patterns/Opera-senza-titolo-min.jpg";
import people from "C:/Users/Sean/Documents/VSCode Files/giulia_website/src/Images/people/Scarabocchi-min.jpg";
import animals from "C:/Users/Sean/Documents/VSCode Files/giulia_website/src/Images/animals/ClownFish-min.jpg";
import food from "C:/Users/Sean/Documents/VSCode Files/giulia_website/src/Images/food_and_beverages/Opera-senza-titolo-min.jpg";
import comm from "C:/Users/Sean/Documents/VSCode Files/giulia_website/src/Images/commissions/Roberto-E-Silvia-min.jpg";
import book from "C:/Users/Sean/Documents/VSCode Files/giulia_website/src/Images/childrens_book/Capitolo-12-min.jpg";





export const projectPreviewImages = [
  {
    key: 0,
    path: people,
  },
  {
    key: 1,
    path: animals,
  },
  {
    key: 2,
    path: food,
  },
  {
    key: 3,
    path: comm,
  },
  {
    key: 4,
    path: pattern,
  },
  {
    key: 5,
    path: book,
  },
];
