import HomePageGallary from "../../components/HomePageGallary/HomePageGallary";

import "./homePage.css"

function HomePage(){
    return(
        <div className="home-page-container">
            <HomePageGallary />
        </div>
    )
}

export default HomePage;