import pinkBlonde from "C:/Users/Sean/Documents/VSCode Files/giulia_website/src/Images/people/Debby-Harry-min.jpg";
import giraffe from "C:/Users/Sean/Documents/VSCode Files/giulia_website/src/Images/animals/Giraffa-min.jpg";
import spaceMan from "C:/Users/Sean/Documents/VSCode Files/giulia_website/src/Images/people/Stelle-min.jpg";
import donuts from "C:/Users/Sean/Documents/VSCode Files/giulia_website/src/Images/food_and_beverages/Donuts--min.jpg";
import comm_4 from "C:/Users/Sean/Documents/VSCode Files/giulia_website/src/Images/commissions/Paolo--min.jpg";
import frog from "C:/Users/Sean/Documents/VSCode Files/giulia_website/src/Images/animals/Rana-min.jpg";


export const homePageImages = [
  {
    key: 0,
    path: pinkBlonde,
  },
  {
    key: 1,
    path: giraffe,
  },
  {
    key: 2,
    path: spaceMan,
  },
  {
    key: 3,
    path: donuts,
  },
  {
    key: 4,
    path: comm_4,
  },
  {
    key: 5,
    path: frog,
  },
];
