import  "./landingPageComps.css"

function TitleBar(){
    return(
        <div className="title-bar-container">
            <h1 className="main-title">- GIULIA FRATI -</h1>
        </div>
    )
}

export default TitleBar;